<template>
  <v-sheet
      :width="cardWidth"
      :max-width="cardWidth"
      class="fill-height px-6 pb-16 d-flex flex-column "
      :color="BLOCK_COLOR_PARAM(block , 'slider1_card_background' , block.config.style )"
  >
    <ws-img
        v-if="item.img"
        :src="item.img"
        :height="config.image_height || 220"
        width="100%"
        :vertical-position="item.img_vertical || 50"
        :horizontal-position="item.img_horizontal || 50"
        :contain="item.img_contain"
        auto-size
    />
    <div  >
      <h3 :style="BLOCK_TEXT_CSS(block , block.config.style , 'slider1' , 'title')" class="mt-4">{{ item.title }}</h3>
      <h4 :style="BLOCK_TEXT_CSS(block , block.config.style , 'slider1' , 'text')" class="mt-2">{{ item.text }}</h4>
      <h4 :style="BLOCK_TEXT_CSS(block , block.config.style , 'slider1' , 'description')" class="mt-2" v-html="PARSE_NEW_LINES(item.description)"/>
    </div>
    <div  v-if="item.style_button && item.style_button !== 'text' " class="flex-grow-1" >
    </div>
    <ws-link
        :href="item.link || null"
        new-window
        :disabled="$store.state.avalon.editorMode || ['enroll_on_course','sidebar','dialog'].includes(item.action)"
    >
      <avalon-button
          v-if="item.button_text"
          @click="$emit('action')"
          :block-style="block.config.style"
          :button-style="item.style_button"
          primary-background-param="slider1_button_primary_bg"
          primary-text-color-param="slider1_button_primary"
          secondary-background-param="slider1_button_secondary_bg"
          secondary-text-color-param="slider1_button_secondary"
          :text="item.button_text"
          :block="block"
          :radius="item.button_radius"
          :icon-right="item.button_icon"
          class="mt-2"
          fill-area

      />

      <h4 v-else-if="config.button_text" @click="$emit('action')"
          :style="BLOCK_TEXT_CSS(block , block.config.style , 'slider1' , 'button')"
          class="pointer mt-2"
      >
        {{ config.button_text }}
        <v-icon v-if="config.button_icon">{{ block.config.button_icon }}</v-icon>
      </h4>
    </ws-link>


  </v-sheet>
</template>

<script>
export default {
  name: "blockSliderDesign1",
  props : {
    cardWidth : {},
    item : {
      type : Object,
      default() { return {} }
    },
    config  : {
      type : Object,
      default() { return {} }
    },
    block  : {
      type : Object,
      default() { return { config : {}} }
    }
  }
}
</script>

<style scoped>

</style>